import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Img from "gatsby-image"
import parse from "html-react-parser"

const KapcsolatPage = () => {

  const pageQuery = useStaticQuery(graphql`
      query Kapcsolat {
          markdownRemark(frontmatter: {pagename: {eq: "kapcsolat"}}) {
              frontmatter {
                  title
                  photo {
                      childImageSharp {
                          fluid(maxWidth: 500, fit: CONTAIN) {
                              aspectRatio
                              src
                              srcSet
                              sizes
                          }
                         
                      }
                  }
                  details {
                      label
                      value
                  }
              }
              html
          }
      }

  `)

  const { html, frontmatter } = pageQuery?.markdownRemark
  const { title, photo, details } = frontmatter
  return <>
    <Layout>
      <Seo title={title} />
      <h1>Sövényházi Balázs</h1>
      <p>Az alábbi elérhetőségen felveheted velem a kapcsolatot.</p>
      <section className="contact">
        <div className="column">
          <Img fluid={photo.childImageSharp.fluid} />
        </div>
        <div className="column">
          {details.map((pair, index) => {
            return <p key={`contact-${index}`}>
              <strong>{pair.label}</strong> <br />
              {pair.value}
            </p>
          })}
        </div>
      </section>

      {parse(html || "")}
    </Layout>
  </>
}

export default KapcsolatPage
